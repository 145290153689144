import React from 'react';
import img from '../images/image2.webp';
import { useMediaQuery } from 'react-responsive';

const Intro = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <>
      <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="about">
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img
              alt="card img"
              className="float-right object-cover h-full w-full"
              style={{
                borderRadius: '10%',
                float: 'right',
                objectFit: 'cover',
                height: 'full',
                width: 'full',
              }}
              src={img}
            />
          </div>
          <div
            className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h3
              className={`font-extrabold text-4xl ${isMobile ? 'text-xl' : ''}`}
              style={{
                textShadow: '0 0 2px white',
                fontSize: 30,
                WebkitTextStroke: '0.5px white',
                fontFamily: 'Cyclin',
              }}
            >
              Traditional Thai Massage
            </h3>
            <div>
              <p className="my-3 text-1xl text-gray-300 font-semibold">
                Traditional Thai Massage is a holistic healing practice from
                Thailand that combines acupressure, stretching, and energy work.
                Recipients remain clothed as practitioners apply pressure to
                specific points and perform assisted stretches to promote
                relaxation, relieve muscle tension, improve circulation, and
                stimulate energy flow. It offers physical and mental benefits,
                including pain relief, stress reduction, increased flexibility,
                and overall well-being. Practitioners require training in Thai
                massage techniques and understanding of the body's energy
                systems. Overall, it provides a rejuvenating experience that
                supports natural healing and harmony.
              </p>
            </div>

            <div>
              <p className="my-3 text-1xl text-gray-300 font-semibold">
                Certified Practitioners, Authentic Experience: Our massage salon
                employs certified practitioners who have received formal
                training and hold recognized diplomas in traditional massage
                techniques. Rest assured that you will receive an authentic and
                beneficial experience from our highly qualified professionals.
                Your well-being is our priority.
              </p>
            </div>
            <a
              href="https://www.facebook.com/profile.php?id=100094543849028"
              target="_blank"
              rel="noreferrer"
              className="text-white border bg-blue-mine hover:bg-gray-900 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group"
              style={{ textShadow: '0 0 2px black',WebkitTextStroke: '0.5px white', fontSize:30, color: 'white', fontFamily: 'Cyclin' }}

            
            >
              Contact us
              <svg
                className="w-4 h-4 ml-1 group-hover: translate-x-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
