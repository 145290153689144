import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen'; // Assurez-vous que le chemin est correct
function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const aosInit = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    };

    window.addEventListener('load', () => {
      aosInit();
      // Simulez un délai de 5 secondes (5000 millisecondes) pour le chargement
      setTimeout(() => {
        setLoading(false); // Fin du chargement
      }, 5000);
    });
  }, []);

  useDocTitle('Amonthanat Massage');

  return (
    <>
      {loading ? (
        // Votre composant de page de chargement ici
        // Il sera affiché pendant les 8 secondes de chargement simulées
        <LoadingScreen />
      ) : (
        <Router>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>
          </ScrollToTop>
        </Router>
      )}
    </>
  );
}

export default App;
