import React, { useState, useEffect } from 'react';
import img from '../images/image8.webp';
import img2 from '../images/image10.webp';
import img3 from '../images/image12.webp';
import img4 from '../images/image11.webp';
import menu from '../images/menucardf_forwebsite.webp';
import './Our_massages.css';

const Ourmassages = () => {
  // State variables to track menu open and mobile view
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  // Use effect to update mobile view based on window width
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to handle menu click and toggle menu open state
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div id="our-massages" className="bg-gray-100 py-12">
      <section data-aos="zoom-in-down">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold"
          style={{
            textShadow: '0 0 2px white',
            fontSize: 40,
            fontFamily: 'Cyclin',
            WebkitTextStroke: '0.5px white'
          }}
          >
            Our Massages
          </h2>

          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>

          {/* Add Menu Card text and click event */}
        <div
          className=" my-2 menu-card-text cursor-pointer text-blue-900 text-lg text-center font-bold"
          onClick={handleMenuClick}
        >
          We propose 11 different massages
        </div>

        <div className="my-2 flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>

        <div className={`menu-overlay ${isMenuOpen ? 'menu-open' : ''}`}>
        <img
          src={menu}
          alt="Menu Card"
          className={`menu-image ${isMobileView ? 'mobile-view' : ''} ${!isMobileView ? 'computer-view' : ''}`}
          onClick={handleMenuClick}
        />
      </div>

       
        

        
          
        </div>


        

        
        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-3 lg:grid-cols-3 gap-5">
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm flex flex-col items-center">
                <img
                  alt="card img"
                  className="rounded-full group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60 object-cover"
                  src={img}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  1. Foot Massage <br /> (นวดเท้า) <br /> 250 ฿/hr.
                </h2>
                <p className="text-md text-center font-bold">
                  Thai foot massage is a therapeutic technique originating from
                  Thailand that involves applying pressure to specific points
                  and lines on the feet to promote relaxation, improve
                  circulation, and balance the body's energy. It combines
                  elements of reflexology, stretching, and massage to relieve
                  tension, stimulate the internal organs, and enhance overall
                  well-being.
                </p>
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm flex flex-col items-center">
                <img
                  alt="card img"
                  className="rounded-full group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60 object-cover"
                  src={img2}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  2. Foot Scrub Massage <br /> (สครับเท้า) <br /> 300 ฿/hr.
                </h2>
                <p className="text-md text-center font-bold">
                  The foot scrub massage is a rejuvenating treatment that
                  involves cleansing, exfoliating, and moisturizing the feet. It
                  aims to remove dead skin cells, promote relaxation, and
                  improve circulation. The therapist uses scrubbing techniques
                  and gentle pressure to effectively exfoliate the feet. This
                  process is followed by a soothing massage using oils or
                  lotions to hydrate and nourish the skin. The foot scrub
                  massage stimulates energy flow and provides a refreshing
                  experience, leaving the feet revitalized and rejuvenated.
                </p>
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm flex flex-col items-center">
                <img
                  alt="card img"
                  className="rounded-full group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60 object-cover"
                  src={img3}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  3. Thai massage <br /> (นวดไทย) <br /> 250 ฿/hr.
                </h2>
                <p className="text-md text-center font-bold">
                  Thai massage is a traditional therapeutic practice from
                  Thailand that combines acupressure, assisted yoga stretches,
                  and deep tissue massage techniques. The practitioner uses
                  their hands, elbows, knees, and feet to apply pressure and
                  manipulate the body. Thai massage aims to release tension,
                  improve flexibility, and restore the flow of energy throughout
                  the body. It involves rhythmic movements and stretches that
                  target specific pressure points and energy lines. The massage
                  is performed on a mat on the floor while the recipient wears
                  loose, comfortable clothing. Thai massage promotes relaxation,
                  relieves muscle and joint pain, and enhances overall
                  well-being.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm flex flex-col items-center">
                <img
                  alt="card img"
                  className="rounded-full group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60 object-cover"
                  src={img4}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  4. Thai Deep Tissue Massage <br /> (นวดไทยออย) <br /> 400
                  ฿/hr.
                </h2>
                <p className="text-md text-center font-bold">
                  Thai deep tissue massage is a therapeutic technique that
                  focuses on reaching deeper layers of muscles and connective
                  tissues. The therapist uses firm pressure and slow, deliberate
                  strokes to target specific areas of tension and tightness.
                  This technique helps to release chronic muscle knots and
                  adhesions, promoting improved mobility and pain relief. Thai
                  deep tissue massage combines elements of traditional Thai
                  massage with deep tissue techniques, incorporating stretches
                  and passive movements to enhance the effectiveness of the
                  treatment. It is beneficial for relieving chronic pain,
                  reducing muscle stiffness, and restoring balance to the body.
                  The massage is performed on a mat on the floor, and the
                  recipient may wear loose, comfortable clothing.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm flex flex-col items-center">
                <img
                  alt="card img"
                  className="rounded-full group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60 object-cover"
                  src={img}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  5. Oil Massage <br /> (นวดออย) <br /> 300 ฿/hr.
                </h2>
                <p className="text-md text-center font-bold">
                  Oil massage is a therapeutic technique that involves the
                  application of oils on the body to promote relaxation and
                  healing. The therapist uses various strokes and techniques,
                  such as effleurage and kneading, to manipulate the muscles and
                  soft tissues. The use of oil allows for smooth gliding
                  movements, reducing friction and enhancing the massage
                  experience. The oil helps moisturize the skin, leaving it
                  nourished and supple. Oil massage can help alleviate muscle
                  tension, reduce stress, improve blood circulation, and provide
                  a sense of overall well-being. It is often performed in a
                  calming and soothing environment to enhance relaxation and
                  sensory enjoyment.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm flex flex-col items-center">
                <img
                  alt="card img"
                  className="rounded-full group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60 object-cover"
                  src={img2}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  6. Hot Oil Massage <br /> (นวดน้ำมันร้อน) <br /> 400 ฿/hr.
                </h2>
                <p className="text-md text-center font-bold">
                  A hot oil massage is a therapeutic technique that involves the
                  use of heated oils for a soothing and relaxing experience. The
                  therapist applies warm oil to the body and uses various
                  massage strokes to promote deep relaxation and release muscle
                  tension. The heat from the oil helps to loosen tight muscles
                  and increase blood circulation. The oils used in the massage
                  are often infused with aromatic essential oils for added
                  sensory benefits. The massage session typically covers the
                  entire body, providing a luxurious and nourishing treatment
                  for the skin. Hot oil massage promotes relaxation, relieves
                  stress, and leaves the body feeling rejuvenated and refreshed.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm flex flex-col items-center">
                <img
                  alt="card img"
                  className="rounded-full group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60 object-cover"
                  src={img3}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  7. Oil Aroma Massage <br /> (นวดอโรม่า) <br /> 400 ฿/hr
                </h2>
                <p className="text-md text-center font-bold">
                  Oil aroma massage is a therapeutic treatment that combines the
                  benefits of massage with the soothing effects of aromatic
                  essential oils. The practitioner applies gentle pressure and
                  long, flowing strokes using their hands and fingers, combined
                  with the use of high-quality oils infused with fragrant
                  botanical extracts. The aroma of the oils helps to relax the
                  mind and uplift the mood, while the massage techniques work to
                  release muscle tension, reduce stress, and improve
                  circulation. The combination of the massage and the aromatic
                  oils creates a deeply calming and rejuvenating experience. The
                  treatment can be tailored to address specific concerns or
                  preferences, providing a personalized and aromatic journey of
                  relaxation and well-being.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm flex flex-col items-center">
                <img
                  alt="card img"
                  className="rounded-full group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60 object-cover"
                  src={img4}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  8. Back and Shoulder Massage <br /> (นวด หลัง คอ บ่า ไหล่){' '}
                  <br />
                  350 ฿/hr.
                </h2>
                <p className="text-md text-center font-bold">
                  A back and shoulder massage is a therapeutic treatment that
                  focuses on the upper body. It involves applying pressure,
                  kneading, and manipulating the muscles in the back and
                  shoulder areas. The massage therapist uses their hands,
                  fingers, elbows, and sometimes additional tools to alleviate
                  tension, reduce muscle stiffness, and relieve pain. The
                  treatment may also include techniques such as effleurage,
                  petrissage, and deep tissue massage to promote relaxation and
                  improve blood circulation. Back and shoulder massages can help
                  alleviate stress, improve posture, and provide relief from
                  common issues like knots, stiffness, and tension in the upper
                  body.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm flex flex-col items-center">
                <img
                  alt="card img"
                  className="rounded-full group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60 object-cover"
                  src={img}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  9. Thai Herb Massage <br /> (นวดประคบ) <br /> 450 ฿/hr.
                </h2>
                <p className="text-md text-center font-bold">
                  Thai herb massage, also known as Thai herbal compress massage,
                  is a therapeutic treatment that incorporates the use of heated
                  herbal compresses. These compresses are filled with a blend of
                  traditional Thai herbs, such as lemongrass, ginger, and
                  turmeric. The therapist applies the compresses to different
                  parts of the body, using a combination of pressing, rolling,
                  and kneading motions. The heat and herbal properties of the
                  compresses help to relax muscles, relieve tension, and promote
                  healing. The aromatic herbs also provide a soothing and
                  invigorating sensory experience. Thai herb massage is known
                  for its detoxifying, rejuvenating, and balancing effects on
                  the body and mind.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm flex flex-col items-center">
                <img
                  alt="card img"
                  className="rounded-full group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60 object-cover"
                  src={img2}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  10. Face Massage <br /> (นวดหน้า) <br /> 250 ฿/hr.
                </h2>
                <p className="text-md text-center font-bold">
                Face massage involves applying a soothing blend of creams to facial muscles, accompanied by gentle strokes, pressing, and kneading motions. The therapist utilizes the cream's hydrating properties to promote relaxation, improve circulation, and enhance skin health. This non-invasive technique aims to release tension, reduce stress, and contribute to a radiant complexion. Beyond cosmetic benefits, the cream face massage provides a sense of relaxation and well-being, supporting overall skin nourishment and promoting a healthy, rejuvenated appearance.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm flex flex-col items-center">
                <img
                  alt="card img"
                  className="rounded-full group-hover:scale-[1.15] transition duration-1000 ease-in-out h-60 object-cover"
                  src={img3}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  11. Head Massage <br /> (นวดหัว) <br /> 300 ฿/hr.
                </h2>
                <p className="text-md text-center font-bold">
                Head massage is a therapeutic practice where the therapist employs their hands to apply gentle pressure, circular motions, and kneading techniques to the head, neck, and shoulders. Without additional tools, the therapist uses their skilled hands to release tension, ease headaches, and promote relaxation. This non-invasive approach stimulates blood circulation, providing relief from stress and fatigue. The hands-on technique fosters a soothing sensory experience, offering a holistic approach to well-being. The simplicity of using only the therapist's hands makes this head massage an accessible and effective method for promoting physical and mental relaxation.
                </p>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* <section>
        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
          <div
            className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
            data-aos="zoom-out"
          >
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center ">
              <div className="text-blue-900 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path>
                  <path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path>
                </svg>
              </div>
              <h3
                className="text-3xl  text-blue-900 
                          font-bold"
              >
                We <span className="font-black">Build</span>
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  With over 10 years experience in software analysis and
                  design and a deep understanding of the latest IT trends and
                  solutions, we provide customized recommendations and
                  strategies to help you improve your operations, reduce
                  costs, and increase efficiency.
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
              <div className="text-blue-900 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
                </svg>
              </div>
              <h3 className="text-3xl  text-blue-900 font-bold">
                We <span className="font-black">Collaborate</span>
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  We can collaborate with your existing tech team to scale
                  existing software applications or design customized software
                  applications that suits your everyday need and simplifies
                  various processes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Ourmassages;
