import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Portfolio.css'; // Importez le fichier CSS spécifique
import productImage1 from '../images/a1.webp';
import productImage2 from '../images/a2.webp';
import productImage3 from '../images/a3.webp';
import productImage4 from '../images/a4.webp';
import productImage5 from '../images/a5.webp';
import productImage8 from '../images/a8.webp';
import productImage9 from '../images/a11.webp';
import productImage10 from '../images/a22.webp';
import productImage11 from '../images/bkam.webp';
import productImage12 from '../images/a23.webp';
import productImage13 from '../images/a24.webp';
import productImage14 from '../images/a25.webp';
import productImage15 from '../images/a26.webp';
import productImage16 from '../images/a27.webp';
import productImage17 from '../images/a28.webp';
import productImage18 from '../images/a29.webp';
import productImage19 from '../images/a30.webp';
import productImage20 from '../images/a31.webp';
import productImage21 from '../images/a32.webp';

const Portfolio = () => {
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleFullscreen = (image) => {
    setFullscreenImage((prevImage) => (prevImage === image ? null : image));
  };

  const images = [
    productImage1,
    productImage2,
    productImage3,
    productImage4,
    productImage5,
    productImage8,
    productImage9,
    productImage10,
    productImage11,
    productImage12,
    productImage13,
    productImage14,
    productImage15,
    productImage16,
    productImage17,
    productImage18,
    productImage19,
    productImage20,
    productImage21,
  ];

  return (
    <>
      <div className="my-4 py-4" id="portfolio">
        <h2
          className="my-2 text-center text-3xl text-blue-999 uppercase font-bold"
          style={{
            textShadow: '0 0 2px white',
            fontSize: 40,
            fontFamily: 'Cyclin',
            WebkitTextStroke: '0.5px white',
          }}
        >
          Overview
        </h2>
        <div className="flex justify-center ">
          <div className="w-24 border-b-4 border-blue-1000 mb-8"></div>
        </div>
        <div className="px-4" data-aos="fade-down" data-aos-delay="600">
          {isMobile ? (
            <Carousel
              showArrows={true}
              showThumbs={false}
              infiniteLoop={true}
              useKeyboardArrows={true}
              autoPlay={true}
              interval={3000}
              stopOnHover={true}
              swipeable={true}
              dynamicHeight={true}
              showStatus={false}
              showIndicators={true}
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                const defStyle = { marginLeft: 4, color: 'white', cursor: 'pointer' };
                const style = isSelected ? { ...defStyle, color: 'grey' } : { ...defStyle };
                return (
                  <span
                    style={style}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    aria-label={`${label} ${index + 1}`}
                  >
                    ●
                  </span>
                );
              }}
            >
              {images.map((image, index) => (
                <div
                  key={index}
                  className="carousel-item transition-all ease-in-out duration-400 overflow-hidden text-gray-700 rounded-lg shadow-2xl"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={image}
                    alt={`Product ${index + 1}`}
                    onClick={() => toggleFullscreen(image)}
                    className={`product-image w-full rounded-lg ${
                      fullscreenImage === image ? 'fullscreen-image' : ''
                    }`}
                    
                  />
                </div>
              ))}
            </Carousel>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
              {images.map((image, index) => (
                <div
                  key={index}
                  className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 rounded-lg shadow-2xl flex items-center justify-center"
                  style={{ height: '500px' }}
                >
                  <div className="w-full h-full flex items-center justify-center">
                    <img
                      src={image}
                      alt={`Product ${index + 1}`}
                      className="w-full h-full object-cover rounded-lg cursor-pointer"
                      onClick={() => toggleFullscreen(image)}
                      style={{
                        borderRadius: '0.5rem',
                        border: '4px solid white',
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {fullscreenImage && (
          <div className="fullscreen-container" onClick={closeFullscreen}>
            <img
              src={fullscreenImage}
              alt="Fullscreen View"
              className="fullscreen-image"
            />
          </div>
        )}
      </div>
      <div className="flex justify-center">
        <div className="w-24 border-b-4 border-blue-1000 mb-8"></div>
      </div>
    </>
  );
};

export default Portfolio;
