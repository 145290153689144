import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faLine, faTelegram } from '@fortawesome/free-brands-svg-icons'; // Import the Telegram icon
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Ourmassages from '../components/OurMassages';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const Home = () => {
  const isMobile = window.innerWidth <= 768;

  const floatingIconsStyle = {
    position: 'fixed',
    bottom: isMobile ? '16px' : '32px',
    right: isMobile ? '16px' : '32px',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    zIndex: 9999,
  };

  const iconStyle = {
    backgroundColor: 'white',
    borderRadius: '20%',
    margin: isMobile ? '7px' : '16px',
    padding: isMobile ? '7px' : '16px',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
    cursor: 'pointer',
    animation: 'glow 2s infinite', // Add the glow animation
  };

  const arrowUpStyle = {
    ...iconStyle,
    marginLeft: isMobile ? '5px' : '16px',
    position: 'fixed',
    bottom: isMobile ? '16px' : '32px',
    left: isMobile ? '16px' : '32px',
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Hero />
      <Intro />
      <Ourmassages />
      <Portfolio />
      <Clients />
      <Cta />
      <Footer />

      <div style={floatingIconsStyle}>
  {isMobile ? (
    <>
      <div style={iconStyle}>
        <a href="https://wa.me/+66641831185" target="_blank">
          <FontAwesomeIcon icon={faWhatsapp} size={isMobile ? "lg" : "1x"} />
        </a>
      </div>
      <div style={iconStyle}>
        <a href="https://line.me/R/ti/p/%40886yoeiq" target="_blank">
          <FontAwesomeIcon icon={faLine} size={isMobile ? "lg" : "1x"} />
        </a>
      </div>
      <div style={iconStyle}>
        <a href="https://t.me/+66641831185" target="_blank">
          <FontAwesomeIcon icon={faTelegram} size={isMobile ? "lg" : "1x"} />
        </a>
      </div>
      <div onClick={handleScrollToTop} style={arrowUpStyle}>
        <FontAwesomeIcon icon={faArrowUp} size={isMobile ? "lg" : "1x"} />
      </div>
    </>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={iconStyle}>
        <a href="https://wa.me/+66641831185" target="_blank">
          <FontAwesomeIcon icon={faWhatsapp} size="1x" />
        </a>
      </div>
      <div style={iconStyle}>
        <a href="https://line.me/R/ti/p/%40886yoeiq" target="_blank">
          <FontAwesomeIcon icon={faLine} size="1x" />
        </a>
      </div>
      <div style={iconStyle}>
        <a href="https://t.me/+66641831185" target="_blank">
          <FontAwesomeIcon icon={faTelegram} size="1x" />
        </a>
      </div>
      <div onClick={handleScrollToTop} style={arrowUpStyle}>
        <FontAwesomeIcon icon={faArrowUp} size="1x" />
      </div>
    </div>
  )}
</div>
    </>
  );
};

export default Home;