// NavLinks.js
import React, { useState, useEffect, useCallback } from 'react';
import FloatingIcons from './FloatingIcons';



const NavLinks = ({ isOpen, isMobile, handleClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const linkStyle = isMobile ? 'text-2xl' : 'text-4xl';

  const bubbleStyle = isOpen && !isMobile ? 'dialog-bubble' : '';

  const handleOutsideClick = useCallback((e) => {
    if ((isOpen || isModalOpen) && !e.target.closest('.dialog-bubble')) {
      handleClick();
      setIsModalOpen(false);
    }
  }, [isOpen, isModalOpen, handleClick]);

  const handleScroll = useCallback(() => {
    if (isOpen || isModalOpen) {
      handleClick();
      setIsModalOpen(false);
    }
  }, [isOpen, isModalOpen, handleClick]);

  useEffect(() => {
    if (isOpen || isModalOpen) {
      document.addEventListener('click', handleOutsideClick);
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (isOpen || isModalOpen) {
        document.removeEventListener('click', handleOutsideClick);
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isOpen, isModalOpen, handleOutsideClick, handleScroll]);

  useEffect(() => {
    if (isOpen && isMobile) {
      // If the menu is open and it's a mobile view, close the modal
      setIsModalOpen(false);
    }
  }, [isOpen, isMobile]);

  return (
    <div className={`${bubbleStyle} ${isMobile ? 'flex flex-col items-center' : 'flex items-center space-x-4'}`}>
      <a
        className={`px-4 font-extrabold text-black-500 hover:text-blue-900 ${linkStyle}`}
        href="#our-massages"
        onClick={(e) => {
          e.preventDefault();
          const element = document.getElementById('our-massages');
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
            });
            handleClick();
            setIsModalOpen(false);
          }
        }}
        style={{
          textShadow: '0 0 2px white',
          
          fontFamily: 'Cyclin',
          WebkitTextStroke: '0.5px white'
        }}
      >
        Our Massages
      </a>
      <a
        className={`px-4 font-extrabold text-black-500 hover:text-blue-900 ${linkStyle}`}
        href="https://www.google.com/maps/place/Amonthanat+Massage/@7.9029261,98.3465686,15.83z/data=!4m6!3m5!1s0x305031af2ee373f5:0xb44d9e232635a261!8m2!3d7.89823!4d98.355965!16s%2Fg%2F11kr5xp2b2?entry=ttu"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          handleClick();
          setIsModalOpen(false);
        }}
        style={{
          textShadow: '0 0 2px white',
          
          fontFamily: 'Cyclin',
          WebkitTextStroke: '0.5px white'
        }}
      >
        Find Us
      </a>

      {/* New link for opening Floating Icons */}
      <a
        className={`text-white bg-blue-mine hover:bg-blue inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl ${linkStyle}`}
        href="#hero"
        
        onClick={(e) => {
          e.preventDefault();
          const element = document.getElementById('hero');
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
            });
            handleClick();
            setIsModalOpen(false);
          }
        }}
        style={{
          textShadow: '0 0 1px white',
          
          fontFamily: 'Cyclin',
          WebkitTextStroke: '0.5px white'
        }}
      >
        Book
      </a>
      
      {isModalOpen && <FloatingIcons onClose={() => setIsModalOpen(false)} />}
    </div>
  );
};

export default NavLinks;
