import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWhatsapp,
  faLine,
  faFacebook,
  faInstagram,
  faTelegram, // Add Telegram icon
} from '@fortawesome/free-brands-svg-icons';
import myImage from '../images/bkam.webp';
import tripadvisorImage from '../images/tripadvisor.svg';
import { Link } from 'react-router-dom';

const targetURLs = {
  whatsapp: 'https://wa.me/+66641831185',
  line: 'https://line.me/R/ti/p/%40886yoeiq',
  facebook: 'https://www.facebook.com/profile.php?id=100094543849028',
  instagram: 'https://www.instagram.com/amonthanat_massage',
  telegram: 'https://t.me/+66641831185', // Add Telegram link
  tripadvisor: 'https://www.tripadvisor.com/Attraction_Review-g297931-d26525903-Reviews-Amonthanat_Massage-Kathu_Phuket.html',
};

const iconColors = {
  whatsapp: '#25d366',
  line: '#00b900',
  facebook: '#1877f2',
  instagram: '#bc2a8d',
  telegram: '#0088cc', // Telegram icon color
};

const Footer = () => {
  const isMobile = window.innerWidth <= 768;

  const getIconStyle = (icon) => ({
    color: iconColors[icon],
  });

  return (
    <>
      <footer>
        <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30 text-center">
          <div className="mx-auto font-bold uppercase text-blue-900">
            <div
              className="my-2 text-center text-3xl text-blue-900 uppercase font-bold"
              style={{
                textShadow: '0 0 2px white',
                fontSize: 30,
                fontFamily: 'Cyclin',
                color:'black',
                WebkitTextStroke: '0.3px white',
              }}
            >
              Follow us on social media.
            </div>
            <div className="mx-auto text-center mt-2">
              <ul className="flex justify-center mb-4 md:mb-0">
                <li>
                  <a
                    href={targetURLs.whatsapp}
                    className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-transparent hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                    aria-label="WhatsApp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      className="w-8 h-8"
                      style={getIconStyle('whatsapp')}
                    />
                  </a>
                </li>
                <li className="ml-4">
                  <a
                    href={targetURLs.line}
                    className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-transparent hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                    aria-label="Line"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faLine}
                      className="w-8 h-8"
                      style={getIconStyle('line')}
                    />
                  </a>
                </li>
                <li className="ml-4">
                  <a
                    href={targetURLs.facebook}
                    className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-transparent hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                    aria-label="Facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="w-8 h-8"
                      style={getIconStyle('facebook')}
                    />
                  </a>
                </li>
                <li className="ml-4">
                  <a
                    href={targetURLs.instagram}
                    className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-transparent hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                    aria-label="Instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="w-8 h-8"
                      style={getIconStyle('instagram')}
                    />
                  </a>
                </li>
                <li className="ml-4">
                  <a
                    href={targetURLs.telegram}
                    className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-transparent hover-bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                    aria-label="Telegram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faTelegram}
                      className="w-8 h-8"
                      style={getIconStyle('telegram')}
                    />
                  </a>
                </li>
                <li className="ml-4">
                  <a
                    href={targetURLs.tripadvisor}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={tripadvisorImage}
                      alt="TripAdvisor"
                      className="w-8 h-8 fill-current"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="box-border border-b-4 border-blue-900 p-8 text-gray-600 text-center rounded-lg xl:w-80 mx-auto"
              style={{
                backgroundImage: `url(${myImage})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <h3
                className={`font-extrabold text-4xl text-blue-900 ${
                  isMobile ? 'text-xl' : ''
                }`}
                style={{ textShadow: '0 0 2px white',WebkitTextStroke: '0.5px white', color: 'black', fontFamily: 'Cyclin', fontSize: 30, }}
              >
                Amonthanat Massage
              </h3>
              <div
                className="text-md font-bold text-gray-900"
                style={{ textShadow: '0 0 10px white', WebkitTextStroke: '0.3px white',color: 'black' }}
              >
                <p>19/8,</p>
                <p>Soi Bae Hoa,</p>
                <p>Kathu,</p>
                <p>Phuket,</p>
                <p>83120,</p>
                <p>Thailand.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
            <div className="text-sm text-gray-200 font-semibold py-1">
              Copyright &copy; {new Date().getFullYear()}
              {'  '}
              <a
                href="#"
                className=" hover:text-blue-900"
              >
                Amonthanat Massage
              </a>
              . All rights reserved. Developed by
              <a
                href="https://www.linkedin.com/in/jean-jacquet"
                className="hover:text-blue-900"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                Jean
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
