import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/logoRM.webp';
import FloatingIcons from './Navbar/FloatingIcons';

const Hero = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Ref for the button element
  const buttonRef = useRef(null);

  // Function to close the menu
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  // Effect to attach click listener to document when menu is open
  useEffect(() => {
    if (isMenuOpen) {
      const handleDocumentClick = (e) => {
        // Check if the clicked element is not the button or a descendant of the button
        if (buttonRef.current && !buttonRef.current.contains(e.target)) {
          closeMenu();
        }
      };

      // Attach the click listener to the document
      document.addEventListener('click', handleDocumentClick);

      // Cleanup the click listener when the component unmounts or menu is closed
      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }
  }, [isMenuOpen]);

  // Effect to close the menu when scrolling
  useEffect(() => {
    const handleScroll = () => {
      closeMenu();
    };

    // Attach the scroll listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup the scroll listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="hero" id="hero">
      <NavBar />
      <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">
        <div className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
          <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
            <h1 className={`mb-5 font-bold text-blue-900 ${isMobile ? 'text-3xl' : 'md:text-5xl'}`} style={{ textShadow: '0 0 2px white', color: 'black', WebkitTextStroke: '0.5px white', fontFamily: 'Cyclin' }}>
              Traditional Thai Massage
            </h1>
            <h2 className={`mb-5 font-bold text-blue-900 ${isMobile ? 'text-1xl' : 'md:text-3xl'}`}
             style={{ textShadow: '0 0 1px white', color: 'black', WebkitTextStroke: '0.5px white'}}
            >
              Open every day from 9:00 am to 9:00 pm
            </h2>
            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
              <button
                ref={buttonRef}
                className={`text-white border bg-blue-mine hover:bg-gray-900 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl ${isMobile ? 'sm:w-full' : 'sm:w-auto sm:mb-0'}`}
                onClick={handleMenuToggle}
                style={{ textShadow: '0 0 2px black', fontSize:30,WebkitTextStroke: '0.5px white', color: 'white', fontFamily: 'Cyclin' }}
              >
                <div className="flex items-center">
                  Book
                  <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                  </svg>
                </div>
              </button>

              {isMenuOpen && <FloatingIcons onClose={handleMenuToggle} />}
            </div>
          </div>
          <div className={`flex $ w-full lg:w-1/2`} data-aos="fade-up" data-aos-delay="700">
          <img
  alt="card img"
  className={`mx-auto rounded-t duration-1000 ${
    isMobile ? 'w-full h-auto' : 'max-w-md'
  }`}
  src={heroImg}
/> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
