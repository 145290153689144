import React from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

const mapContainerStyle = {
  height: '400px',
  width: '100%',
};

const center = {
  lat: 7.89823,
  lng: 98.355965,
};

const mapOptions = {
  mapTypeControl: true,
  mapTypeId: 'hybrid',
};

function Clients() {
  return (
    <div className="mt-8 bg-gray-100">
      <section data-aos="fade-up">
        <div className="my-4 py-4">
          <h2
            className="my-2 text-center text-3xl text-blue-900 uppercase font-bold"
            style={{
              textShadow: '0 0 2px white',
              fontSize: 40,
              fontFamily: 'Cyclin',
              WebkitTextStroke: '0.5px white',
            }}
          >
            Find Us
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <h2
            className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900"
            style={{
              textShadow: '0 0 2px white',
              fontSize: 30,
              fontFamily: 'Cyclin',
              WebkitTextStroke: '0.5px white',
            }}
          >
            Here We Are
          </h2>

          <div className="map-container" style={{ width: '100%' }}>
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={14}
                options={mapOptions}
              >
                <Marker position={center} />
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Clients;
