import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faLine, faTelegram } from '@fortawesome/free-brands-svg-icons';

const FloatingIcons = ({ onClose }) => {
  const isMobile = window.innerWidth <= 700;

  return (
    <div className="floating-icons-container">
      <div className="floating-icons-text"
      style={{
        
        WebkitTextStroke: '0.3px white'
      }}
      >
        Contact us directly via Line, Telegram, or WhatsApp
      </div>
      <div className="floating-icons-icons">
        <div className="floating-icons-icon">
          <a href="https://wa.me/+66641831185" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
          </a>
        </div>
        <div className="floating-icons-icon">
          <a href="https://line.me/R/ti/p/%40886yoeiq" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLine} size="2x" />
          </a>
        </div>
        <div className="floating-icons-icon">
          <a href="https://t.me/+66641831185" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTelegram} size="2x" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FloatingIcons;