import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavLinks from '../Navbar/NavLinks';
import logoImage from '../../images/logoRM.webp';
import tripadvisorImage from '../../images/tripadvisor.svg'; // Chemin vers l'image TripAdvisor
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWhatsapp,
  faLine,
  faFacebook,
  faInstagram,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons';

const NavBar = () => {
  const [top, setTop] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };



  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };

    const resizeHandler = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener('scroll', scrollHandler);
    window.addEventListener('resize', resizeHandler);

    resizeHandler();

    return () => {
      window.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const targetURLs = {
    whatsapp: 'https://www.whatsapp.com/',
    line: 'https://line.me/en/',
    facebook: 'https://www.facebook.com/profile.php?id=100094543849028',
    instagram: 'https://www.instagram.com/amonthanat_massage',
    tripadvisor: 'https://www.tripadvisor.com/Attraction_Review-g297931-d26525903-Reviews-Amonthanat_Massage-Kathu_Phuket.html', // URL TripAdvisor
    telegram: 'https://t.me/+66641831185',  
  };

  const containerStyle = {
    fontSize: isMobile ? '28px' : '40px',
    display: 'flex',
    alignItems: 'center',
  };

  const logoStyle = {
    height: isMobile ? '40px' : '60px',
    width: isMobile ? '40px' : '60px',
    marginRight: isMobile ? '4px' : '8px',
  };

  // Styles personnalisés pour les icônes des réseaux sociaux
  const socialIconStyles = {
    color: {
      // Définissez ici les couleurs officielles des réseaux sociaux
      whatsapp: '#25d366', // Couleur WhatsApp
      line: '#00b900', // Couleur Line
      facebook: '#1877f2', // Couleur Facebook
      instagram: '#bc2a8d', // Couleur Instagram
      telegram: '#0088cc',
      tripadvisor: 'currentColor', // Utilisez la couleur actuelle pour TripAdvisor
    },
  };

  return (
    <nav
      className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out mb-16 ${
        !top && 'bg-white shadow-lg'
      }`}
    >
      <div className="flex flex-row justify-between items-center py-2">
        <div className="flex flex-row items-center space-x-5">
          <Link to="/" className="flex items-center ml-8">
            <div style={containerStyle}>
              <img
                src={logoImage}
                alt="Amonthanat Massage Logo"
                style={logoStyle}
              />
              <h1
                className={`font-extrabold text-2x1 text-blue-900 ${
                  isMobile ? 'text-2x' : ''
                }`}
                style={{
                  textShadow: '0 0 2px white',
                  color: 'black',
                  fontFamily: 'Cyclin',
                  WebkitTextStroke: '0.5px white',
            
                }}
              >
                Amonthanat Massage
              </h1>
            </div>
          </Link>

          {!isMobile && (
            <div className="flex space-x-5">
              <a
                href={targetURLs.whatsapp}
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-icon"
                style={{ color: socialIconStyles.color.whatsapp }}
              >
                <FontAwesomeIcon icon={faWhatsapp} size="lg" />
              </a>
              <a
                href={targetURLs.line}
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-icon"
                style={{ color: socialIconStyles.color.line }}
              >
                <FontAwesomeIcon icon={faLine} size="lg" />
              </a>
              <a
                href={targetURLs.telegram}
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-icon"
                style={{ color: socialIconStyles.color.telegram }}
              >
                <FontAwesomeIcon icon={faTelegram} size="lg" />
              </a>
              

              <a
                href={targetURLs.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-icon"
                style={{ color: socialIconStyles.color.facebook }}
              >
                <FontAwesomeIcon icon={faFacebook} size="lg" />
              </a>
              <a
                href={targetURLs.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-icon"
                style={{ color: socialIconStyles.color.instagram }}
              >
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </a>
              <a
                href={targetURLs.tripadvisor}
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-icon"
              >
                <img
                  src={tripadvisorImage}
                  alt="TripAdvisor Logo"
                  style={{
                    height: '25px',
                    width: '25px',
                    color: socialIconStyles.color.tripadvisor,
                  }}
                />
              </a>
            </div>
          )}
        </div>
        <div className="group flex flex-col items-center">
          <button
            className="p-2 rounded-lg lg:hidden text-blue-900 menu-button"
            onClick={handleClick}
            aria-label={isOpen ? 'Close menu' : 'Open menu'} // Add ARIA label for accessibility
          >
            <svg
              className="h-6 w-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              {isOpen && (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.829a1 1 0 1 1-1.414-1.414l4.829-4.828-4.828-4.829a1 1 0 1 1 1.414-1.414l4.828 4.829 4.829-4.828a1 1 0 1 1 1.414 1.414l-4.829 4.828 4.828 4.829z"
                />
              )}
              {!isOpen && (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.5 4h19a1 1 0 0 1 0 2h-19a1 1 0 0 1 0-2zm0 7h19a1 1 0 0 1 0 2h-19a1 1 0 0 1 0-2zm0 7h19a1 1 0 0 1 0 2h-19a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>
          <div className="hidden space-x-6 lg:inline-block p-5">
            <NavLinks
              isOpen={isOpen}
              handleClick={handleClick}
              isMobile={isMobile}
            />
          </div>

          {isOpen && (
            <div className="fixed transition-transform duration-300 ease-in-out transit flex justify-center left-0 w-full h-auto rounded-md p-24 bg-white rounded-lg block lg:hidden shadow-xl top-14">
              <div className="flex flex-col space-y-6">
                <NavLinks
                  isOpen={isOpen}
                  handleClick={handleClick}
                  isMobile={isMobile}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
